// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("bootstrap")
require("@fortawesome/fontawesome-free")
require("tempusdominus-bootstrap-4")
require("daterangepicker")

require("moment/locale/tr")

require('@kollegorna/cocoon-vanilla-js')

require('summernote/dist/summernote-bs4.js');
require('summernote/dist/lang/summernote-tr-TR');

require('select2/dist/js/select2.full')
require('select2/dist/js/i18n/tr')

import './styles.scss';

window.jQuery = $;
window.$ = $;
window.moment = moment;

global.toastr = require("toastr")
global.toastr.options = {
    "positionClass": "toast-bottom-right",
    "timeOut": "10000"
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//$(function () {
$( document ).on('turbolinks:load', function() {

    $('.date_time_picker').datetimepicker({locale: 'tr'});

    $('.date_picker').datetimepicker({locale: 'tr', format: 'L'});

    $('[data-toggle="tooltip"]').tooltip()

    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
    });

    $('.select2').select2({
        theme: 'bootstrap4',
    });

    $('.summernote').summernote({
        codeviewFilter: true,
        codeviewIframeFilter: true,
        lang: 'tr-TR',
        height: 200,
        prettifyHtml: true,
        toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'clear']],
            ['fontname', ['fontname']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table']],
            ['insert', ['link']],
            ['view', ['fullscreen', 'help']],
        ]
    })

    $('.summernote-report-section').summernote({
        codeviewFilter: true,
        codeviewIframeFilter: true,
        lang: 'tr-TR',
        height: 200,
        prettifyHtml: true,
        toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'clear']],
            ['color', ['color']],
            ['view', ['codeview', 'help']],
        ]
    })
});